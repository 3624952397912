import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg">
          <div className="bp">
            <div className="wrapper bp bp2">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="link"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="current">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>Bądź piękna</h2>
                <h3>
                  Nawilżona i dobrze odżywiona skóra jest wizytówką każdej
                  zadbanej kobiety. Z myślą o kobietach takich jak Ty,
                  przygotowaliśmy{" "}
                  <span className="bold">Przewodnik Pielęgnacyjny</span>, w
                  którym znajdziesz porady dotyczące codziennej pielęgnacji
                  skóry. Odkryj w sobie naturalne piękno <br /> i dowiedz się,
                  jak skutecznie pielęgnować skórę każdego dnia!
                  <br />
                  <br />
                  <span className="bold">
                    Wybierz temat i dowiedz się więcej!
                  </span>
                </h3>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  Bądź piękna
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="content boxcontent">
              <div className="row">
                <a href="/site/bad-piekna/test-skory/" className="bpbox left">
                  <img src="/site/assets/Uploads/kafel1.jpg" alt="kafel1" />
                  <span className="title">Test skóry</span>
                  <p>
                    Odpowiedz na kilka pytań na temat Twojej skóry i jej
                    pielęgnacji i dowiedz się, która linia kosmetyków NEUTROGENA
                    <sup>®</sup> jest najlepiej dopasowana do jej potrzeb
                  </p>
                  <span className="button">więcej</span>
                </a>
                <a
                  href="/site/bad-piekna/ogolnopolskie-badanie-skory/"
                  className="bpbox right"
                >
                  <img src="/site/assets/Uploads/kafel2.jpg" alt="kafel2" />
                  <span className="title">Ogólnopolskie Badanie Skóry</span>
                  <p>
                    Odwiedź stoisko NEUTROGENA<sup>®</sup>, wykonaj bezpłatne
                    badanie USG skóry i wybierz najlepszy produkt NEUTROGENA
                    <sup>®</sup> dla siebie.
                  </p>
                  <span className="button">więcej</span>
                </a>
              </div>
              <div className="row">
                <a href="/site/bad-piekna/sucha-skora/" className="bpbox left">
                  <img src="/site/assets/Uploads/bp2.jpg" alt="bp2" />
                  <span className="title">Sucha skóra</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    <span>
                      Nawilżona i dobrze odżywiona od wewnątrz skóra jest
                      wizytówką każdej zadbanej kobiety.
                    </span>
                  </p>
                  <span className="button">więcej</span>
                </a>
                <a
                  href="/site/bad-piekna/pielegnacja-twarzy/"
                  className="bpbox right"
                >
                  <img src="/site/assets/Uploads/bp3.jpg" alt="bp3" />
                  <span className="title">Pielęgnacja twarzy</span>
                  <p>
                    <span>
                      Chcesz mieć ładną i zdrową cerę? Dowiedz się co na ma nią
                      wpływ.
                    </span>
                  </p>
                  <span className="button">więcej</span>
                </a>
              </div>
              <div className="row">
                <a
                  href="/site/bad-piekna/pielegnacja-ust/"
                  className="bpbox left"
                >
                  <img src="/site/assets/Uploads/bp4.jpg" alt="bp4" />
                  <span className="title">Pielęgnacja ust</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    <span>
                      Usta są najczęściej zaniedbywaną częścią naszego ciała. Co
                      trzeba o nich wiedzieć?
                    </span>
                  </p>
                  <span className="button">więcej</span>
                </a>
                <a
                  href="/site/bad-piekna/pielegnacja-stop/"
                  className="bpbox right"
                >
                  <img src="/site/assets/Uploads/bp6.jpg" alt="bp6" />
                  <span className="title">Pielęgnacja stóp</span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    <span>
                      Rytuał pielęgnacyjny, który pozwoli Ci cieszyć się
                      pięknymi stopami przez cały rok.
                    </span>
                  </p>
                  <span className="button">więcej</span>
                </a>
              </div>
              <div className="row">
                <a
                  href="/site/bad-piekna/pielegnacja-ciala/"
                  className="bpbox left"
                >
                  <img src="/site/assets/Uploads/bp7.jpg" alt="bp7" />
                  <span className="title">
                    Pielęgnacja ciała i regeneracja skóry
                  </span>
                  <p
                    style={{
                      height: "45px"
                    }}
                  >
                    <span>
                      Zdrowa i piękna skóra wymaga przemyślanej pielęgnacji
                      każdego dnia.
                    </span>
                  </p>
                  <span className="button">więcej</span>
                </a>
                <a
                  href="/site/bad-piekna/pielegnacja-doni/"
                  className="bpbox right"
                >
                  <img src="/site/assets/Uploads/bp5.jpg" alt="bp5" />
                  <span className="title">Pielęgnacja dłoni</span>
                  <span className="button">więcej</span>
                </a>
              </div>
              <div id="social_id_9"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
